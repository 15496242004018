import React, { FC } from "react";
import { ScreenTitle } from "context/ScreenTitle";

type NoMatchScreenProps = {};

export const NoMatchScreen: FC<NoMatchScreenProps> = () => {
  return (
    <div className="NoMatchScreen">
      <ScreenTitle title="EviCore P2P - 404" />
      <p>Page not found.</p>
    </div>
  );
};
