import { FC } from "react";
import { OldSignInForm } from "./OldSignInForm";
import evicoreLogoSrc from "images/evicore_logo_full.png";

export const OldSignInScreen: FC = () => {
  return (
    <div className="bg-gray-50 sm:px-6 lg:px-8 flex flex-col justify-center min-h-screen py-12">
      <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
        <img
          className="w-auto h-24 mx-auto"
          src={evicoreLogoSrc}
          alt="EviCore P2P Logo"
        />
        <p className="text-accent-green-500 py-2 text-lg font-bold">
          P2P Portal
        </p>

        <h2 className="mt-12 text-3xl font-extrabold leading-9 text-center text-teal-500">
          Sign in to your account
        </h2>
        <p className="max-w mt-2 text-sm leading-5 text-center text-gray-600">
          Sign in to access the EviCore P2P portal.
        </p>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md mt-8">
        <OldSignInForm refetchQueries={[]} />
      </div>
    </div>
  );
};
