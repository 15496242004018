import React, { FC, useCallback } from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "context/AuthContext";
import { FormStatusErrors } from "components/formik/FormStatusErrors";

const LOGIN_AGENT = gql`
  mutation LoginAgent($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      errors {
        key
        message
      }
      session {
        token
        agent {
          id
          firstName
          timeZoneName
          lastSignInAt
        }
      }
    }
  }
`;

interface MutationData {
  login: {
    errors?: InputError[];
    session?: {
      token: string;
      agent: {
        id: string;
        firstName?: string;
        timeZoneName?: string;
        lastSignInAt: string;
      };
    };
  };
}

interface MutationVariables {
  email: string;
  password: string;
}

interface FormValues {
  email: string;
  password: string;
}

interface SignInFormProps {
  refetchQueries?: any;
}

export const OldSignInForm: FC<SignInFormProps> = () => {
  const [signIn] = useMutation<MutationData, MutationVariables>(LOGIN_AGENT);
  const { login } = useAuth();

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      signIn({
        variables: values,
      }).then(
        (resp) => {
          if (resp?.data?.login.errors) {
            setStatus({ errors: resp.data.login.errors });
          } else if (resp?.data?.login?.session?.token) {
            // it worked
            return login(resp.data.login.session.token);
          }
          setSubmitting(false);
        },
        (rej) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong." }],
          });
          setSubmitting(false);
        }
      );
    },
    [signIn, login]
  );

  return (
    <div className="_OldSignInForm sm:rounded-2xl sm:px-10 px-4 py-8 bg-white shadow-xl">
      <Formik<FormValues>
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .trim()
            .required("Required"),
          password: Yup.string().required("Required"),
        })}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <Field
                  className="form-input w-full"
                  name="email"
                  placeholder="Email"
                  autoFocus
                />
              </div>
              <ErrorMessage
                component="p"
                name="email"
                className="mt-2 text-xs italic text-red-500"
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Password
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <Field
                  className="form-input w-full"
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </div>
              <ErrorMessage
                component="p"
                name="password"
                className="mt-2 text-xs italic text-red-500"
              />
            </div>
            <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:shadow-outline-yellow active:bg-teal-700 flex justify-center w-full px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out bg-teal-600 border border-transparent rounded-md"
                >
                  Sign in
                </button>
              </span>
            </div>
            <div className="flex items-center justify-end py-1 mt-3"></div>
          </form>
        )}
      </Formik>
    </div>
  );
};
