import React, { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CircleIcon, AppColor } from "@ovicare/ui";

/**
 * ModalHeader
 */
interface ModalHeaderProps {
  icon: IconProp;
  color?: AppColor;
  title: string;
}

export const ModalHeader: FC<ModalHeaderProps> = (props) => {
  const { icon, color = "blue", title } = props;

  return (
    <div className="_ModalHeader flex items-center p-4">
      <CircleIcon icon={icon} color={color} />
      <h5 className="md:text-xl ml-3 text-lg font-medium text-gray-800 truncate">
        {title}
      </h5>
    </div>
  );
};
