import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { Formik } from "formik";
import { SelectField, Button, FAIcon, Spinner } from "@ovicare/ui";
import { PreferredDaysField } from "components/formik/PreferredDaysField";
import { PreferredTimesField } from "components/formik/PreferredTimesField";
import { defaultDays } from "components/PreferredDays";
import { flatDefaultTimes } from "components/PreferredTimes";

const days = defaultDays;

const allTimes = flatDefaultTimes;

const AVAILABILITY_FORM_QUERY = gql`
  query AvailabilityFormQuery($id: UUID4!) {
    timeZoneNames
    me {
      id
      timeZoneName
    }
    appointmentRequest(id: $id) {
      id
      timePreference {
        id
        preferredDays
        preferredTimes
        timeZoneName
      }
    }
  }
`;

interface Data {
  timeZoneNames: string[];
  me: {
    id: string;
    timeZoneName: string;
  };
  appointmentRequest: {
    id: string;
    timePreference?: {
      id: string;
      preferredDays: string[];
      preferredTimes: string[];
      timeZoneName: string;
    };
  };
}

const UPDATE_TIME_PREFERENCE = gql`
  mutation UpdateTimePreference(
    $appointmentRequestId: UUID4!
    $timePreference: TimePreferenceInput!
  ) {
    updateAppointmentRequestTimePreference(
      id: $appointmentRequestId
      timePreference: $timePreference
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
        timePreference {
          id
          preferredDays
          preferredTimes
          timeZoneName
        }
      }
    }
  }
`;

interface MutationData {
  updateAppointmentRequestTimePreference: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
      timePreference: {
        id: string;
        preferredDays: string[];
        preferredTimes: string[];
        timeZoneName: string;
      };
    };
  };
}

interface AvailabilityFormProps {
  appointmentRequestId: string;
}

export const AvailabilityForm: FC<AvailabilityFormProps> = (props) => {
  const { appointmentRequestId } = props;
  const history = useHistory();

  const { data, loading, error } = useQuery<Data>(AVAILABILITY_FORM_QUERY, {
    variables: { id: appointmentRequestId },
  });
  const [updateTimePreference] = useMutation<MutationData>(
    UPDATE_TIME_PREFERENCE
  );

  return (
    <div className="_AvailabilityForm">
      {loading ? (
        <div className="p-10 text-center">
          <Spinner />
        </div>
      ) : error || !(data && data.timeZoneNames) ? (
        <div style={{ padding: "1.5rem" }}>
          <h1>Failed to Load</h1>
        </div>
      ) : (
        <Formik
          initialValues={{
            preferredDays:
              data.appointmentRequest.timePreference?.preferredDays || days,
            preferredTimes:
              data.appointmentRequest.timePreference?.preferredTimes ||
              allTimes,
            timeZoneName:
              data.appointmentRequest.timePreference?.timeZoneName ||
              data.me.timeZoneName ||
              "",
          }}
          validationSchema={Yup.object().shape({
            preferredDays: Yup.array()
              .required("Required")
              .min(1, "You must select at least 1 day")
              .ensure()
              .of(Yup.mixed().oneOf(days)),
            preferredTimes: Yup.array()
              .required("Required")
              .min(1, "You must select at least 1 time range")
              .ensure()
              .of(Yup.mixed().oneOf(allTimes)),
            timeZoneName: Yup.string().required("Required"),
          })}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus({ errors: null });
            const variables = {
              appointmentRequestId,
              timePreference: values,
            };
            updateTimePreference({
              variables,
            }).then(
              (resp) => {
                if (resp?.data?.updateAppointmentRequestTimePreference.errors) {
                  setStatus({
                    errors:
                      resp.data.updateAppointmentRequestTimePreference.errors,
                  });
                } else if (
                  resp?.data?.updateAppointmentRequestTimePreference
                    .appointmentRequest
                ) {
                  // it worked...
                  return history.push(
                    `/cw/p2p_request_wizard/${resp.data.updateAppointmentRequestTimePreference.appointmentRequest.id}/results`
                  );
                }
                setSubmitting(false);
              },
              () => {
                setStatus({
                  errors: [{ key: "", message: "Something went wrong." }],
                });
              }
            );
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: "1.75rem" }}>
                <PreferredDaysField
                  name="preferredDays"
                  label="Preferred Days"
                  showInstructions={false}
                />
              </div>

              <div style={{ marginBottom: "1.75rem" }}>
                <PreferredTimesField
                  name="preferredTimes"
                  label="Preferred Times"
                  showInstructions={false}
                />
              </div>

              <div style={{ marginBottom: "1.75rem " }}>
                <SelectField
                  name="timeZoneName"
                  label="Time Zone"
                  options={data.timeZoneNames.map((tz: string) => ({
                    value: tz,
                    label: tz,
                  }))}
                  matchFrom="any"
                />
              </div>

              <div className="max-w-3xl pt-5 mx-auto mt-8 border-t border-gray-200">
                <div className="flex justify-end">
                  <span className="inline-flex ml-3 rounded-md shadow-sm">
                    <Button type="submit" color="teal">
                      Continue
                      <span className="ml-2">
                        <FAIcon icon="chevron-right" />
                      </span>
                    </Button>
                  </span>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};
