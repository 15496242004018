import { FC } from "react";
import { AuthenticatedApp } from "./AuthenticatedApp";
import { UnauthenticatedApp } from "./UnauthenticatedApp";
import { Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "components/PrivateRoute";
import { NoMatchScreen } from "screens/NoMatchScreen";
import { SAMLRedirectScreen } from "screens/SAMLRedirectScreen";
import { SessionTimeoutScreen } from "screens/SessionTimeoutScreen/SessionTimeoutScreen";
import { LoggedOutScreen } from "screens/LoggedOutScreen/LoggedOutScreen";

export const App: FC = () => {
  return (
    <div className="_App">
      <Switch>
        <Route exact path="/">
          <Redirect to="/auth/sign_in" />
        </Route>
        <Route exact path="/saml_redirect">
          <SAMLRedirectScreen />
        </Route>
        <Route exact path="/session_expired">
          <SessionTimeoutScreen />
        </Route>
        <Route exact path="/logged_out">
          <LoggedOutScreen />
        </Route>
        <Route path="/auth">
          <UnauthenticatedApp />
        </Route>
        <PrivateRoute path="/cw">
          <AuthenticatedApp />
        </PrivateRoute>
        <Route path="*">
          <NoMatchScreen />
        </Route>
      </Switch>
    </div>
  );
};
