import { FC, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logoSrc from "images/evicore_logo_full.png";
import { useScreenTitle } from "context/ScreenTitle";
import { CurrentAgentMenu } from "components/CurrentAgentMenu";
import { CaptureTimeZone } from "./CaptureTimeZone";
import { Plus_md as PlusIcon } from "components/Heroicons";

/**
 * TopNav.
 */
interface TopNavProps {
  sidebarOpen: boolean;
  setSidebarOpen(isOpen: boolean): void;
}
const TopNav: FC<TopNavProps> = (props) => {
  const { setSidebarOpen } = props;
  const { titleString, shortTitle } = useScreenTitle();

  return (
    <div className="relative z-10 flex flex-shrink-0 h-16 bg-white shadow">
      <button
        onClick={() => setSidebarOpen(true)}
        className="focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden px-4 text-gray-500 border-r border-gray-200"
      >
        <svg
          className="w-6 h-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>
      <div className="flex justify-between flex-1 px-4">
        <div className="flex flex-1">
          <div className="md:ml-0 flex w-full">
            <div className="flex items-center w-full text-gray-400">
              <h2 className="md:inline-block md:mx-4 md:text-lg hidden text-base font-bold text-gray-700 whitespace-no-wrap">
                {titleString}
              </h2>
              <h2 className="md:hidden md:mx-4 md:text-lg inline-block text-base font-bold text-gray-700 whitespace-no-wrap">
                {shortTitle}
              </h2>
            </div>
          </div>
        </div>
        <div className="md:ml-6 flex items-center ml-4">
          {/* <button className="hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500 p-1 text-gray-400 rounded-full">
            <svg
              className="w-6 h-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          </button> */}
          <div className="relative ml-3">
            <CurrentAgentMenu />
            {/* <div>
              <button
                onClick={() => setSidebarOpen(!sidebarOpen)}
                className="focus:outline-none focus:shadow-outline flex items-center max-w-xs text-sm rounded-full"
              >
                <img
                  className="w-8 h-8 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Sidebar.
 */
interface SidebarProps {
  isOpen: boolean;
  onClose(): void;
}

const Sidebar: FC<SidebarProps> = (props) => {
  const { isOpen, onClose } = props;

  return (
    <>
      {/* Off-canvas menu for mobile */}
      <div
        onClick={onClose}
        className={`fixed inset-0 z-30 bg-gray-600 opacity-0 pointer-events-none transition-opacity ease-linear duration-300 ${
          isOpen
            ? "opacity-75 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      />
      <div
        className={`fixed inset-y-0 left-0 flex flex-col z-40 max-w-xs w-full transform ease-in-out duration-300 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
        style={{ backgroundColor: "#002855" }}
      >
        <div className="-mr-14 absolute top-0 right-0 p-1">
          {isOpen ? (
            <button
              onClick={onClose}
              className="focus:outline-none focus:bg-gray-600 flex items-center justify-center w-12 h-12 rounded-full"
            >
              <svg
                className="w-6 h-6 text-white"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          ) : null}
        </div>
        <div className="flex items-center flex-shrink-0 h-16 px-4 bg-white border-r">
          <img
            className="w-auto h-12 mx-auto"
            src={logoSrc}
            alt="EviCore P2P"
          />
        </div>
        <div className="flex-1 h-0 overflow-y-auto">
          <nav className="px-2 py-4">
            <div className="flex justify-center">
              <span className="inline-flex rounded-md shadow-sm">
                <Link
                  to="/cw/p2p_request_wizard/new"
                  className="bg-gold-600 hover:bg-gold-500 focus:outline-none focus:border-gold-700 focus:shadow-outline-yellow active:bg-gold-700 inline-flex items-center px-4 py-2 text-sm font-medium font-bold leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md"
                >
                  <PlusIcon className="w-5 h-5 mr-2 -ml-1" />
                  New P2P Request
                </Link>
              </span>
            </div>
            <NavLink
              to="/cw/dashboard"
              className="group text-navy-300 hover:text-white hover:bg-navy-700 focus:outline-none focus:text-white focus:bg-navy-700 flex items-center px-2 py-2 mt-4 text-base font-medium leading-6 transition duration-150 ease-in-out rounded-md"
              activeClassName="text-white bg-gray-900 hover:bg-gray-900"
            >
              <svg
                className="text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 w-6 h-6 mr-4 transition duration-150 ease-in-out"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"
                />
              </svg>
              Dashboard
            </NavLink>
            <NavLink
              to="/cw/requests"
              className="group text-navy-300 hover:text-white hover:bg-navy-700 focus:outline-none focus:text-white focus:bg-navy-700 flex items-center px-2 py-2 mt-1 text-base font-medium leading-6 transition duration-150 ease-in-out rounded-md"
              activeClassName="text-white bg-gray-900 hover:bg-gray-900"
            >
              <svg
                className="text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 w-6 h-6 mr-4 transition duration-150 ease-in-out"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              My P2P Requests
            </NavLink>
          </nav>
        </div>
      </div>

      {/* Static sidebar for desktop */}
      <div className="md:flex md:flex-shrink-0 hidden">
        <div className="flex flex-col w-64">
          <div className="flex items-center flex-shrink-0 h-16 px-4 bg-white border-r">
            <img
              className="w-auto h-12 mx-auto"
              src={logoSrc}
              alt="EviCore P2P"
            />
          </div>
          <div className="flex flex-col flex-1 h-0 overflow-y-auto">
            <nav className="flex-1 px-2 py-4 bg-teal-500">
              <div className="flex justify-center">
                <span className="inline-flex rounded-md shadow-sm">
                  <Link
                    to="/cw/p2p_request_wizard/new"
                    className="bg-mint-600 hover:bg-mint-500 focus:outline-none focus:border-mint-700 focus:shadow-outline-yellow active:bg-mint-700 text-dark-teal-900 inline-flex items-center px-4 py-2 text-sm font-bold leading-5 transition duration-150 ease-in-out border border-transparent rounded-md"
                  >
                    <PlusIcon className="w-5 h-5 mr-2 -ml-1" />
                    New P2P Request
                  </Link>
                </span>
              </div>
              <NavLink
                to="/cw/dashboard"
                className="group text-navy-300 hover:text-white hover:bg-teal-700 focus:outline-none focus:text-white focus:bg-teal-700 flex items-center px-2 py-2 mt-4 text-sm font-medium leading-5 transition duration-150 ease-in-out rounded-md"
                activeClassName="text-white bg-dark-teal-500 hover:bg-dark-teal-700"
              >
                <svg
                  className="group-hover:text-teal-100 group-focus:text-teal-100 w-6 h-6 mr-3 text-teal-100 transition duration-150 ease-in-out"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"
                  />
                </svg>
                Dashboard
              </NavLink>
              <NavLink
                to="/cw/requests"
                className="group text-navy-300 hover:text-white hover:bg-teal-700 focus:outline-none focus:text-white focus:bg-teal-700 flex items-center px-2 py-2 mt-1 text-sm font-medium leading-5 transition duration-150 ease-in-out rounded-md"
                activeClassName="text-white bg-dark-teal-500 hover:bg-dark-teal-700"
              >
                <svg
                  className="text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 w-6 h-6 mr-3 transition duration-150 ease-in-out"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                My P2P Requests
              </NavLink>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * PortalLayout.
 */

interface PortalLayoutProps {}

export const PortalLayout: FC<PortalLayoutProps> = (props) => {
  const { children } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div
      className="_PortalLayout flex h-screen overflow-hidden"
      style={{ backgroundColor: "#f9feff" }}
    >
      <CaptureTimeZone />
      <Sidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <TopNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main
          className="focus:outline-none relative z-0 flex-1 overflow-y-auto"
          tabIndex={0}
        >
          {children}
        </main>
      </div>
    </div>
  );
};
