import { FC, useState, useEffect } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import Select from "react-select";
import {
  FAIcon,
  Tooltip,
  ToggleSwitch,
  VerticalField,
  InputText,
  Button,
} from "@ovicare/ui";
import { ProviderResultsFilterModel as FilterModel, StateModel } from "./types";

export const APPT_REQUEST_AVAILABILITIES_FILTER_BOOTSTRAP = gql`
  query ApptRequestAvailabilitiesFilterBootstrap(
    $appointmentRequestId: UUID4!
  ) {
    appointmentRequest(id: $appointmentRequestId) {
      id
      caseProfiles {
        id
        pathwaySkillIds
        skillOptions {
          id
          name
          externalSystemName
        }
        allowedProviderDomainIds
        excludedProviderDomainIds
        sameSpecialtyMatchRequired
        sameStateLicensureRequired
        orderingPhysicianSpecialty {
          id
          name
        }
        sameStateLicensureState {
          id
          name
          abbreviation
        }
        modality {
          id
          caseProgram {
            caseProgramSkillPrioritizations {
              id
              priority
              skill {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

interface Data {
  appointmentRequest: {
    id: string;
    caseProfiles: CaseProfile[];
  };
}

interface Variables {
  appointmentRequestId: string;
}

interface CaseProfile {
  id: string;
  skillOptions: SkillOption[];
  pathwaySkillIds: string[];
  allowedProviderDomainIds?: string[];
  excludedProviderDomainIds?: string[];
  sameSpecialtyMatchRequired: boolean;
  sameStateLicensureRequired: boolean;
  orderingPhysicianSpecialty?: {
    id: string;
    name: string;
  };
  sameStateLicensureState?: StateModel;
  modality: {
    id: string;
    caseProgram: {
      caseProgramSkillPrioritizations: CaseProgramSkillPrioritization[];
    };
  };
}

type SkillOption = {
  id: string;
  name: string;
  externalSystemName: string;
};

type CaseProgramSkillPrioritization = {
  id: string;
  priority: number;
  skill: Skill;
};

type Skill = {
  id: string;
  name: string;
};

const NetworkIcon: FC<{ size?: number }> = (props) => {
  const { size = 16 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4 5.90909C15.2825 5.90909 16 5.17528 16 4.27273C16 3.37017 15.2825 2.63636 14.4 2.63636C13.5175 2.63636 12.8 3.37017 12.8 4.27273C12.8 5.17528 13.5175 5.90909 14.4 5.90909ZM1.6 7.13636C0.7175 7.13636 0 7.87017 0 8.77273C0 9.67528 0.7175 10.4091 1.6 10.4091C2.4825 10.4091 3.2 9.67528 3.2 8.77273C3.2 7.87017 2.4825 7.13636 1.6 7.13636ZM12.84 6.18523L12.36 5.53068L11.16 6.45114L11.64 7.10568L12.84 6.18523ZM14.4 10.8182C14.04 10.8182 13.71 10.946 13.4425 11.1506L11.0425 9.67784C11.1375 9.39148 11.2 9.08977 11.2 8.77017C11.2 7.1875 9.9475 5.90653 8.4 5.90653C8.19 5.90653 7.985 5.93466 7.79 5.98068L6.77 3.74602C7.035 3.45455 7.2 3.06591 7.2 2.63636C7.2 1.73381 6.4825 1 5.6 1C4.7175 1 4 1.73381 4 2.63636C4 3.53892 4.7175 4.27273 5.6 4.27273C5.6275 4.27273 5.6525 4.26506 5.68 4.26506L6.705 6.50994C6.0375 7.03153 5.6 7.84716 5.6 8.77273C5.6 10.3554 6.8525 11.6364 8.4 11.6364C9.2025 11.6364 9.92 11.2861 10.43 10.7338L12.825 12.204C12.8125 12.2858 12.8 12.3702 12.8 12.4545C12.8 13.3571 13.5175 14.0909 14.4 14.0909C15.2825 14.0909 16 13.3571 16 12.4545C16 11.552 15.2825 10.8182 14.4 10.8182ZM8.4 10C7.7375 10 7.2 9.45028 7.2 8.77273C7.2 8.09517 7.7375 7.54545 8.4 7.54545C9.0625 7.54545 9.6 8.09517 9.6 8.77273C9.6 9.45028 9.0625 10 8.4 10ZM3.8 9.18182H5V8.36364H3.8V9.18182Z"
        fill="currentColor"
      />
    </svg>
  );
};

/**
 * FilterForm.
 */
interface FilterFormProps {
  skills: Skill[];
  allowedProviderDomainIds?: string[];
  excludedProviderDomainIds?: string[];
  sameSpecialtyMatchRequired: boolean;
  sameStateLicensureRequired: boolean;
  orderingPhysicianSpecialty?: {
    id: string;
    name: string;
  };
  sameStateLicensureState?: StateModel;
  initialValue: FilterModel;
  onSubmit(filter: FilterModel): void;
  fetching: boolean;
}

const FilterForm: FC<FilterFormProps> = (props) => {
  const {
    skills,
    allowedProviderDomainIds,
    excludedProviderDomainIds,
    sameSpecialtyMatchRequired,
    sameStateLicensureRequired,
    sameStateLicensureState,
    orderingPhysicianSpecialty,
    initialValue,
    onSubmit,
    fetching,
  } = props;
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>(
    skills.filter(
      (s) => initialValue.skillIds && initialValue.skillIds.includes(s.id)
    )
  );
  const [searchTerm, setSearchTerm] = useState(initialValue.searchTerm || "");
  // NB: Not all appt requests have allow/exclude provider_domain_id filters:
  const domainIdFiltersExist = !!(
    allowedProviderDomainIds || excludedProviderDomainIds
  );
  const [
    applyProviderDomainIdFilters,
    setApplyProviderDomainIdFilters,
  ] = useState(domainIdFiltersExist);

  const showStateLicenseFilter =
    sameStateLicensureRequired && !!sameStateLicensureState;
  const showSpecialtyFilter =
    sameSpecialtyMatchRequired && !!orderingPhysicianSpecialty;

  const [applyStateLicenseFilter, setApplyStateLicenseFilter] = useState(
    showStateLicenseFilter
  );
  const [applySpecialtyFilter, setApplySpecialtyFilter] = useState(
    showSpecialtyFilter
  );

  const doSubmit = () => {
    // NB: This is about to get mutated
    let newFilter: FilterModel = {
      skillIds: (selectedSkills || []).map((s) => s.id),
      searchTerm,
    };
    if (applyProviderDomainIdFilters) {
      if (!!allowedProviderDomainIds) {
        newFilter.allowedProviderDomainIds = allowedProviderDomainIds;
      }
      if (!!excludedProviderDomainIds) {
        newFilter.excludedProviderDomainIds = excludedProviderDomainIds;
      }
    }
    if (applySpecialtyFilter && !!orderingPhysicianSpecialty) {
      newFilter.specialtyId = orderingPhysicianSpecialty.id;
    }
    if (applyStateLicenseFilter && !!sameStateLicensureState) {
      newFilter.licensedInStateId = sameStateLicensureState.id;
    }
    onSubmit(newFilter);
  };

  // Submit on mount
  useEffect(() => {
    doSubmit();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (process.env.REACT_APP_SHOW_EXTERNAL_PORTAL_RESULTS_FILTER !== "true") {
    return null;
  }

  return (
    <form
      className="ProviderResultsPage__FilterPanel bg-gray-100 border border-gray-300 rounded-lg shadow-md"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();

        doSubmit();
      }}
    >
      <div className="p-4">
        <div className="flex items-end justify-center -mx-2">
          <VerticalField
            label={
              <>
                <span className="mr-2">
                  <FAIcon icon="stethoscope" />
                </span>
                Skills
              </>
            }
            className="w-64 mx-2"
          >
            <Select
              isMulti
              id="skillIdsSelect"
              options={skills}
              getOptionLabel={(option: any) =>
                `${option.name} (${option.externalSystemName})`
              }
              getOptionValue={(option: any) => option.id}
              onChange={(values: any) => setSelectedSkills(values)}
              value={selectedSkills}
            />
          </VerticalField>
          <VerticalField
            className="w-64 mx-2"
            label={
              <>
                <span className="mr-2">
                  <FAIcon icon="search" />
                </span>
                Provider Name
              </>
            }
          >
            <InputText
              value={searchTerm}
              onChange={setSearchTerm}
              inputProps={{ placeholder: "Provider Name" }}
            />
          </VerticalField>

          {domainIdFiltersExist ||
          showSpecialtyFilter ||
          showStateLicenseFilter ? (
            <div className="w-64 px-4 mx-2">
              {domainIdFiltersExist ? (
                <div
                  className="flex items-center text-left"
                  style={{ height: 30 }}
                >
                  <p className="flex items-center flex-1 text-sm font-semibold">
                    <span className="inline-flex mr-2 text-gray-700">
                      <NetworkIcon size={14} />
                    </span>
                    Pathway filter
                  </p>
                  <div>
                    <ToggleSwitch
                      id="apply-provider-domain-id-filters-toggle"
                      checked={applyProviderDomainIdFilters}
                      onChange={() =>
                        setApplyProviderDomainIdFilters(
                          !applyProviderDomainIdFilters
                        )
                      }
                      size={16}
                      showLabel
                    />
                  </div>
                </div>
              ) : null}
              {showStateLicenseFilter ? (
                <div
                  className="flex items-center text-left"
                  style={{ height: 30 }}
                >
                  <p className="flex items-center flex-1 text-sm font-semibold">
                    <span className="inline-flex mr-2 text-gray-700">
                      <FAIcon icon="map-marker-alt" />
                    </span>
                    Same State License
                    <span
                      className="inline-flex ml-2 text-purple-500"
                      style={{
                        position: "relative",
                        zIndex: 10,
                        cursor: "pointer",
                        marginTop: 2,
                      }}
                    >
                      <Tooltip tip={sameStateLicensureState!.name}>
                        <FAIcon icon="question-circle" />
                      </Tooltip>
                    </span>
                  </p>
                  <div>
                    <ToggleSwitch
                      id="apply-state-licensure-filter-toggle"
                      checked={applyStateLicenseFilter}
                      onChange={() => {
                        setApplyStateLicenseFilter(!applyStateLicenseFilter);
                      }}
                      size={16}
                      showLabel
                    />
                  </div>
                </div>
              ) : null}
              {showSpecialtyFilter ? (
                <div
                  className="flex items-center text-left"
                  style={{ height: 30 }}
                >
                  <p className="flex items-center flex-1 text-sm font-semibold">
                    <span className="inline-flex mr-2 text-gray-700">
                      <FAIcon icon="stethoscope" />
                    </span>
                    Same Specialty
                    <span
                      className="inline-flex ml-2 text-purple-500"
                      style={{
                        position: "relative",
                        zIndex: 10,
                        cursor: "pointer",
                        marginTop: 2,
                      }}
                    >
                      <Tooltip tip={orderingPhysicianSpecialty!.name}>
                        <FAIcon icon="question-circle" />
                      </Tooltip>
                    </span>
                  </p>
                  <div>
                    <ToggleSwitch
                      id="apply-specialty-filter-toggle"
                      checked={applySpecialtyFilter}
                      onChange={() => {
                        setApplySpecialtyFilter(!applySpecialtyFilter);
                      }}
                      size={16}
                      showLabel
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          <Button
            className="mx-2"
            type="submit"
            color="teal"
            disabled={fetching}
          >
            Search
          </Button>
        </div>
      </div>
    </form>
  );
};

interface FilterPanelProps {
  appointmentRequestId: string;
  value: FilterModel;
  onChange(filter: FilterModel): void;
}

export const FilterPanel: FC<FilterPanelProps> = (props) => {
  const { appointmentRequestId, onChange } = props;
  const { data, loading, error } = useQuery<Data, Variables>(
    APPT_REQUEST_AVAILABILITIES_FILTER_BOOTSTRAP,
    { variables: { appointmentRequestId } }
  );

  return loading ? (
    <p>Loading...</p>
  ) : error || !(data && data.appointmentRequest) ? (
    <div style={{ padding: "1.5rem" }}>
      <h1>Failed to Load</h1>
    </div>
  ) : (
    <FilterForm
      skills={data.appointmentRequest.caseProfiles[0].skillOptions}
      allowedProviderDomainIds={
        data.appointmentRequest.caseProfiles[0].allowedProviderDomainIds
      }
      excludedProviderDomainIds={consolidateExcludedProviderDomainIds(
        data.appointmentRequest.caseProfiles
      )}
      sameSpecialtyMatchRequired={data.appointmentRequest.caseProfiles.some(
        (cp) => cp.sameSpecialtyMatchRequired
      )}
      sameStateLicensureRequired={data.appointmentRequest.caseProfiles.some(
        (cp) => cp.sameStateLicensureRequired
      )}
      orderingPhysicianSpecialty={
        data.appointmentRequest.caseProfiles.find(
          (cp) => !!cp.orderingPhysicianSpecialty
        )?.orderingPhysicianSpecialty
      }
      sameStateLicensureState={
        data.appointmentRequest.caseProfiles.find(
          (cp) => !!cp.sameStateLicensureState
        )?.sameStateLicensureState
      }
      initialValue={{
        searchTerm: "",
        skillIds: data.appointmentRequest.caseProfiles[0].pathwaySkillIds,
      }}
      onSubmit={onChange}
      fetching={loading}
    />
  );
};

function consolidateExcludedProviderDomainIds(
  caseProfiles: CaseProfile[]
): string[] {
  return caseProfiles.reduce((acc, elem) => {
    return acc.concat(elem.excludedProviderDomainIds || []);
  }, [] as string[]);
}
