import React, { FC } from "react";
import { FAIcon, Tooltip } from "@ovicare/ui";
import { CommentEvent } from "./CommentEvent";
import { parseISO, lightFormat } from "date-fns";
import { AppointmentRequestEventModel, EventType } from "../types";

interface AppointmentRequestEventProps {
  event: AppointmentRequestEventModel;
}

export const AppointmentRequestEvent: FC<AppointmentRequestEventProps> = (
  props
) => {
  const { event } = props;

  switch (event.type) {
    case EventType.Scheduled: {
      return (
        <div
          className="AppointmentRequestEvent"
          style={{ margin: "0.75rem 0" }}
        >
          <div className="flex items-center">
            <div
              className="flex-1"
              style={{ maxWidth: 114, margin: 12, textAlign: "right" }}
            >
              <span style={{ fontSize: "0.75em", color: "#aaa" }}>
                {lightFormat(parseISO(event.insertedAt), "M/d/yy - h:mm a")}
              </span>
            </div>
            <div className="flex-1" style={{ fontSize: "0.875rem" }}>
              <p>
                <FAIcon icon={["far", "calendar-check"]} />{" "}
                {event.user ? (
                  <Tooltip tip="EviCore PSU">
                    <strong>
                      {event.user.firstName} {event.user.lastName}
                    </strong>
                  </Tooltip>
                ) : (
                  <strong>
                    {event.agent?.firstName} {event.agent?.lastName}
                  </strong>
                )}{" "}
                scheduled this request with{" "}
                <strong>{event.data.scheduledWith}</strong>.
              </p>
            </div>
          </div>
        </div>
      );
    }
    case EventType.AppointmentCancelled: {
      return (
        <div
          className="AppointmentRequestEvent"
          style={{ margin: "0.75rem 0" }}
        >
          <div className="flex items-center">
            <div
              className="flex-1"
              style={{ maxWidth: 114, margin: 12, textAlign: "right" }}
            >
              <span style={{ fontSize: "0.75em", color: "#aaa" }}>
                {lightFormat(parseISO(event.insertedAt), "M/d/yy - h:mm a")}
              </span>
            </div>
            <div className="flex-1" style={{ fontSize: "0.875rem" }}>
              <p>
                <FAIcon icon={["far", "calendar-times"]} />{" "}
                {event.user ? (
                  <Tooltip tip="EviCore PSU">
                    <strong>
                      {event.user.firstName} {event.user.lastName}
                    </strong>
                  </Tooltip>
                ) : (
                  <strong>
                    {event.agent?.firstName} {event.agent?.lastName}
                  </strong>
                )}{" "}
                cancelled an appointment with{" "}
                <strong>{event.data.wasScheduledWith}</strong>.
              </p>
            </div>
          </div>
        </div>
      );
    }
    case EventType.AppointmentMissed: {
      return (
        <div
          className="AppointmentRequestEvent"
          style={{ margin: "0.75rem 0" }}
        >
          <div className="flex items-center">
            <div
              className="flex-1"
              style={{ maxWidth: 114, margin: 12, textAlign: "right" }}
            >
              <span style={{ fontSize: "0.75em", color: "#aaa" }}>
                {lightFormat(parseISO(event.insertedAt), "M/d/yy - h:mm a")}
              </span>
            </div>
            <div className="flex-1" style={{ fontSize: "0.875rem" }}>
              <p>
                <FAIcon icon={["far", "calendar-times"]} />{" "}
                {event.user ? (
                  <Tooltip tip="EviCore PSU">
                    <strong>
                      {event.user.firstName} {event.user.lastName}
                    </strong>
                  </Tooltip>
                ) : (
                  <strong>
                    {event.agent?.firstName} {event.agent?.lastName}
                  </strong>
                )}{" "}
                marked an appointment with{" "}
                <strong>{event.data.wasScheduledWith}</strong> as missed.
              </p>
            </div>
          </div>
        </div>
      );
    }
    case EventType.Comment: {
      return (
        <div style={{ margin: "0.75rem 0" }}>
          <CommentEvent event={event} />
        </div>
      );
    }
    case EventType.AppointmentCompletionToggled: {
      if (event.data.completed) {
        return (
          <div
            className="AppointmentRequestEvent"
            style={{ margin: "0.75rem 0" }}
          >
            <div className="flex items-center">
              <div
                className="flex-1"
                style={{ maxWidth: 114, margin: 12, textAlign: "right" }}
              >
                <span style={{ fontSize: "0.75em", color: "#aaa" }}>
                  {lightFormat(parseISO(event.insertedAt), "M/d/yy - h:mm a")}
                </span>
              </div>
              <div className="flex-1" style={{ fontSize: "0.875rem" }}>
                <p>
                  <FAIcon icon={["far", "calendar-check"]} />{" "}
                  {event.user ? (
                    <Tooltip tip="EviCore PSU">
                      <strong>
                        {event.user.firstName} {event.user.lastName}
                      </strong>
                    </Tooltip>
                  ) : (
                    <strong>
                      {event.agent?.firstName} {event.agent?.lastName}
                    </strong>
                  )}{" "}
                  completed an appointment.
                </p>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            className="AppointmentRequestEvent"
            style={{ margin: "0.75rem 0" }}
          >
            <div className="flex items-center">
              <div
                className="flex-1"
                style={{ maxWidth: 114, margin: 12, textAlign: "right" }}
              >
                <span style={{ fontSize: "0.75em", color: "#aaa" }}>
                  {lightFormat(parseISO(event.insertedAt), "M/d/yy - h:mm a")}
                </span>
              </div>
              <div className="flex-1" style={{ fontSize: "0.875rem" }}>
                <p>
                  <FAIcon icon="calendar-alt" />{" "}
                  {event.user ? (
                    <Tooltip tip="EviCore PSU">
                      <strong>
                        {event.user.firstName} {event.user.lastName}
                      </strong>
                    </Tooltip>
                  ) : (
                    <strong>
                      {event.agent?.firstName} {event.agent?.lastName}
                    </strong>
                  )}{" "}
                  marked an appointment as not completed.
                </p>
              </div>
            </div>
          </div>
        );
      }
    }
    default:
      return null;
  }
};
