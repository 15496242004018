import { FC, useCallback, useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import { Formik, FormikHelpers, useFormikContext } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import {
  TextField,
  PhoneMaskField,
  PlainSelectField,
  Spinner,
  Button,
  FAIcon,
  RadioGroupField,
  VerticalField,
  TextInput,
} from "@ovicare/ui";

const MY_CONTACT_INFORMATION = gql`
  query MyContactInfo {
    me {
      id
      firstName
      lastName
      email
    }
  }
`;

interface Data {
  me: CurrentAgent;
}

interface CurrentAgent {
  id: string;
  firstName: string;
  lastName?: string;
  email?: string;
}

const BOOK_APPOINTMENT_REQUEST = gql`
  mutation SendAppointmentRequestWithAppointment(
    $id: UUID4!
    $appointmentRequest: ContactDetailsInput!
    $appointment: AppointmentInput!
  ) {
    sendAppointmentRequestWithAppointment(
      id: $id
      appointmentRequest: $appointmentRequest
      appointment: $appointment
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface MutationData {
  sendAppointmentRequestWithAppointment: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

const contactPersonLocationOptions: Option[] = [
  {
    value: "Provider Office",
    label: "Provider Office",
  },
  {
    value: "Health Plan",
    label: "Health Plan",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const credentialOptions: Option[] = [
  { value: "", label: "Select..." },
  ...[
    "MD",
    "NP",
    "PA",
    "DO",
    "APN/APRN",
    "OB",
    "PT",
    "OT",
    "ST",
    "DC",
    "Acupuncturist",
    "MT",
    "Genetic Counselor",
    "Other",
  ].map((val) => ({
    value: val,
    label: val,
  })),
];

const whoIsPerformingP2pOptions: Option[] = [
  {
    value: "Requesting Provider",
    label: "Requesting Provider",
  },
  {
    value: "Contact Person",
    label: "Contact Person",
  },
  {
    value: "Other",
    label: "Someone else",
  },
];

interface FormValues {
  requestingProviderFirstName: string;
  requestingProviderLastName: string;
  requestingProviderCredential: string;
  otherRequestingProviderCredential: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonCredential: string;
  otherContactPersonCredential: string;
  contactPersonLocation: string;
  whoIsPerformingP2p: string;
  otherP2pContactFirstName: string;
  otherP2pContactLastName: string;
  otherP2pContactCredential: string;
  otherP2pContactOtherCredential: string;
  contactPhoneNumber: string;
  contactPhoneNumberExtension: string;
  alternateContactPhoneNumber: string;
  alternateContactPhoneNumberExtension: string;
  contactEmail: string;
  contactInstructions: string;
}

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    requestingProviderFirstName: Yup.string().required("Required"),
    requestingProviderLastName: Yup.string().required("Required"),
    requestingProviderCredential: Yup.string().required("Required"),
    contactPersonFirstName: Yup.string().required("Required"),
    contactPersonLastName: Yup.string().required("Required"),
    whoIsPerformingP2p: Yup.string().required("Required"),
    contactPhoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    alternateContactPhoneNumber: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid"
    ),
    contactEmail: Yup.string()
      .email("Invalid email address")
      .required("Required"),
    contactInstructions: Yup.string(),
  })
  .required();

/**
 * ConfirmationForm.
 */

interface ConfirmationFormProps {
  appointmentRequestId: string;
  startTime: string;
  finishTime: string;
  providerId: string;
  onScheduleConflict(): void;
}

export const ConfirmationForm: FC<ConfirmationFormProps> = (props) => {
  const {
    appointmentRequestId,
    startTime,
    finishTime,
    providerId,
    onScheduleConflict,
  } = props;
  const history = useHistory();

  const { data, loading, error } = useQuery<Data>(MY_CONTACT_INFORMATION);
  const [bookAppointment] = useMutation<MutationData>(BOOK_APPOINTMENT_REQUEST);

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting, setFieldError } = formikActions;

      setStatus({ errors: null });

      const extraErrors = validateExtras(values);

      if (Object.keys(extraErrors).length > 0) {
        for (let key in extraErrors) {
          setFieldError(key, extraErrors[key]);
        }
        setSubmitting(false);
        return;
      }

      const variables = {
        id: appointmentRequestId,
        appointmentRequest: {
          ...values,
          requestingProviderCredential:
            values.requestingProviderCredential === "Other"
              ? values.otherRequestingProviderCredential.trim()
              : values.requestingProviderCredential,
          otherRequestingProviderCredential: undefined,
          contactPersonCredential:
            values.contactPersonCredential === "Other"
              ? values.otherContactPersonCredential.trim()
              : values.contactPersonCredential,
          otherContactPersonCredential: undefined,
          otherP2pContactCredential:
            values.otherP2pContactCredential === "Other"
              ? values.otherP2pContactOtherCredential.trim()
              : values.otherP2pContactCredential,
          otherP2pContactOtherCredential: undefined,
        },
        appointment: {
          timeRange: {
            start: startTime,
            finish: finishTime,
          },
          receivingProviderId: providerId,
        },
      };

      try {
        const { data } = await bookAppointment({ variables });

        if (data?.sendAppointmentRequestWithAppointment.errors) {
          const { errors } = data.sendAppointmentRequestWithAppointment;
          if (isScheduleConflict(errors)) {
            onScheduleConflict();
          }
          setStatus({ errors });
        } else if (
          data?.sendAppointmentRequestWithAppointment.appointmentRequest
        ) {
          // it worked...
          const {
            appointmentRequest,
          } = data.sendAppointmentRequestWithAppointment;
          return history.push(`/cw/requests/${appointmentRequest.id}`);
        }
        setSubmitting(false);
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
        setSubmitting(false);
      }
    },
    [
      appointmentRequestId,
      bookAppointment,
      onScheduleConflict,
      startTime,
      finishTime,
      providerId,
      history,
    ]
  );

  return (
    <div className="ConfirmationForm">
      {loading ? (
        <div className="p-8 text-center">
          <Spinner />
        </div>
      ) : error || !(data && data.me) ? (
        <p>Failed to load.</p>
      ) : (
        <Formik<FormValues>
          initialValues={{
            requestingProviderFirstName: data.me.firstName || "",
            requestingProviderLastName: data.me.lastName || "",
            requestingProviderCredential: "",
            otherRequestingProviderCredential: "",
            contactPersonFirstName: data.me.firstName || "",
            contactPersonLastName: data.me.lastName || "",
            contactPersonCredential: "",
            otherContactPersonCredential: "",
            whoIsPerformingP2p: "",
            otherP2pContactFirstName: "",
            otherP2pContactLastName: "",
            otherP2pContactCredential: "",
            otherP2pContactOtherCredential: "",
            contactPersonLocation: "Provider Office",
            contactPhoneNumber: "",
            contactPhoneNumberExtension: "",
            alternateContactPhoneNumber: "",
            alternateContactPhoneNumberExtension: "",
            contactEmail: data.me.email || "",
            contactInstructions: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, status, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <div className="p-6 mt-4">
                <RadioGroupField
                  name="whoIsPerformingP2p"
                  label="Who will be performing the P2P consultation?"
                  options={whoIsPerformingP2pOptions}
                />
              </div>

              <section className="p-4 mt-4 border rounded">
                <h4 className="px-5 py-2 text-sm font-semibold text-gray-400 uppercase">
                  <FAIcon icon="user-md" className="mr-2" />
                  Requesting Provider
                </h4>
                <div className="flex mt-4">
                  <div className="flex-1 mr-1">
                    <VerticalField label="Name of Provider on Case">
                      <div className="grid grid-cols-2 gap-2">
                        <div>
                          <TextInput
                            name="requestingProviderFirstName"
                            placeholder="First Name"
                          />
                        </div>
                        <div>
                          <TextInput
                            name="requestingProviderLastName"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                    </VerticalField>
                  </div>
                  <div className="w-36 ml-1">
                    <PlainSelectField
                      name="requestingProviderCredential"
                      label="Credential"
                      options={credentialOptions}
                    />
                  </div>
                </div>

                {values.requestingProviderCredential === "Other" ? (
                  <div className="mt-4">
                    <TextField
                      name="otherRequestingProviderCredential"
                      label="Please specify provider credential"
                    />
                  </div>
                ) : null}
              </section>

              <section className="p-4 mt-4 border rounded">
                <div className="flex items-center">
                  <h4 className="px-5 py-2 text-sm font-semibold text-gray-400 uppercase">
                    <FAIcon icon={["far", "address-card"]} className="mr-2" />
                    Contact Person
                  </h4>

                  <SameAsProviderCheckbox className="ml-4" />
                </div>
                <div className="flex mt-4">
                  <div className="grid flex-1 grid-cols-2 gap-3">
                    <div>
                      <TextField
                        name="contactPersonFirstName"
                        label="Contact First Name"
                      />
                    </div>
                    <div>
                      <TextField
                        name="contactPersonLastName"
                        label="Contact Last Name"
                      />
                    </div>
                  </div>
                  {values.whoIsPerformingP2p === "Contact Person" ? (
                    <div className="w-36 ml-2">
                      <PlainSelectField
                        name="contactPersonCredential"
                        label="Credential"
                        options={credentialOptions}
                      />
                    </div>
                  ) : null}
                </div>

                {values.whoIsPerformingP2p === "Contact Person" &&
                values.contactPersonCredential === "Other" ? (
                  <div className="mt-4">
                    <TextField
                      name="otherContactPersonCredential"
                      label="Please specify provider credential"
                    />
                  </div>
                ) : null}

                <div className="mt-4">
                  <PlainSelectField
                    name="contactPersonLocation"
                    label="Contact Person Location"
                    options={contactPersonLocationOptions}
                  />
                </div>
              </section>

              <section className="p-4 mt-4 border rounded">
                <h4 className="px-5 py-2 text-sm font-semibold text-gray-400 uppercase">
                  <FAIcon icon={"phone"} className="mr-2" />
                  P2P Information
                </h4>
                {values.whoIsPerformingP2p === "Other" ? (
                  <>
                    <div className="flex mt-4">
                      <div className="flex-1 mr-1">
                        <VerticalField label="Name of Person Performing P2P">
                          <div className="grid grid-cols-2 gap-2">
                            <div>
                              <TextInput
                                name="otherP2pContactFirstName"
                                placeholder="First Name"
                              />
                            </div>
                            <div>
                              <TextInput
                                name="otherP2pContactLastName"
                                placeholder="Last Name"
                              />
                            </div>
                          </div>
                        </VerticalField>
                      </div>
                      <div className="w-36 ml-1">
                        <PlainSelectField
                          name="otherP2pContactCredential"
                          label="Credential"
                          options={credentialOptions}
                        />
                      </div>
                    </div>
                    {values.otherP2pContactCredential === "Other" ? (
                      <div className="mt-4">
                        <TextField
                          name="otherP2pContactOtherCredential"
                          label="Please specify credential for Person Performing P2P"
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}

                <div className="flex mt-4 -mx-2">
                  <div className="flex-1 mx-2">
                    <PhoneMaskField
                      name="contactPhoneNumber"
                      label="Phone Number for P2P"
                      icon="phone"
                    />
                  </div>

                  <div className="w-36 mx-2">
                    <TextField
                      name="contactPhoneNumberExtension"
                      label="Phone Ext."
                      icon="phone"
                    />
                  </div>
                </div>

                <div className="flex mt-4 -mx-2">
                  <div className="flex-1 mx-2">
                    <PhoneMaskField
                      name="alternateContactPhoneNumber"
                      label="Alternate Phone"
                      icon="phone"
                    />
                  </div>

                  <div className="w-36 mx-2">
                    <TextField
                      name="alternateContactPhoneNumberExtension"
                      label="Phone Ext."
                      icon="phone"
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <TextField
                    name="contactEmail"
                    label="Requesting Provider Email"
                  />
                </div>

                <div className="mt-4">
                  <TextField
                    name="contactInstructions"
                    label="Contact Instructions"
                  />
                </div>
              </section>

              <div className="max-w-3xl pt-5 mx-auto mt-8 border-t border-gray-200">
                <div className="flex justify-end">
                  <span className="inline-flex ml-3 rounded-md shadow-sm">
                    <Button type="submit" color="teal" disabled={isSubmitting}>
                      Submit
                      <span className="ml-2">
                        <FAIcon icon="chevron-right" />
                      </span>
                    </Button>
                  </span>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

function SameAsProviderCheckbox(props: { className?: string }) {
  const { className = "" } = props;
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const [checked, setChecked] = useState(false);

  function handleChange() {
    const newValue = !checked;
    setChecked(newValue);

    if (newValue) {
      setFieldValue(
        "contactPersonFirstName",
        values.requestingProviderFirstName
      );
      setFieldValue("contactPersonLastName", values.requestingProviderLastName);
      setFieldValue(
        "contactPersonCredential",
        values.requestingProviderCredential
      );
    } else {
      setFieldValue("contactPersonFirstName", "");
      setFieldValue("contactPersonLastName", "");
      setFieldValue("contactPersonCredential", "");
    }
  }

  return values.whoIsPerformingP2p === "Requesting Provider" ? (
    <label className={`checkbox flex items-center text-sm ${className}`}>
      <input
        id="sameAsProvider"
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        style={{ marginRight: "0.5rem" }}
        className="focus:ring-blue-500 w-4 h-4 text-blue-600 border-gray-300 rounded"
      />
      Same as Requesting Provider
    </label>
  ) : null;
}

export function isScheduleConflict(errors: InputError[]): boolean {
  return errors.map((e) => e.key).includes("schedule_conflict");
}

function validateExtras(values: FormValues): Record<string, string> {
  let extraErrors: Record<string, string> = {};

  if (
    values.requestingProviderCredential === "Other" &&
    values.otherRequestingProviderCredential.trim() === ""
  ) {
    extraErrors.otherRequestingProviderCredential = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Contact Person" &&
    values.contactPersonCredential.trim() === ""
  ) {
    extraErrors.contactPersonCredential = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Contact Person" &&
    values.contactPersonCredential === "Other" &&
    values.otherContactPersonCredential.trim() === ""
  ) {
    extraErrors.otherContactPersonCredential = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Other" &&
    values.otherP2pContactFirstName.trim() === ""
  ) {
    extraErrors.otherP2pContactFirstName = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Other" &&
    values.otherP2pContactLastName.trim() === ""
  ) {
    extraErrors.otherP2pContactLastName = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Other" &&
    values.otherP2pContactCredential.trim() === ""
  ) {
    extraErrors.otherP2pContactCredential = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Other" &&
    values.otherP2pContactCredential === "Other" &&
    values.otherP2pContactOtherCredential.trim() === ""
  ) {
    extraErrors.otherP2pContactOtherCredential = "Required";
  }

  return extraErrors;
}
