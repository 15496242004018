import { FC } from "react";
import evicoreLogoSrc from "images/evicore_logo_full.png";

export const SignInScreen: FC = () => {
  return (
    <div className="bg-gray-50 sm:px-6 lg:px-8 flex flex-col justify-center min-h-screen py-12">
      <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
        <img
          className="w-auto h-24 mx-auto"
          src={evicoreLogoSrc}
          alt="EviCore Logo"
        />
        <p className="text-accent-green-500 py-2 text-lg font-bold">
          P2P Portal
        </p>

        <h2 className="mt-12 text-2xl font-extrabold leading-9 text-center text-teal-500">
          Please access the EviCore P2P portal through CarriersWeb
        </h2>
      </div>
    </div>
  );
};
