import {
  FC,
  MutableRefObject,
  createContext,
  ReactNode,
  useContext,
  HTMLAttributes,
} from "react";
import { AppColor } from "@ovicare/ui";
import { useDropdown } from "hooks/useDropdown";
import { CheveronDown_sm as ChevronDownIcon } from "components/Heroicons";
import { ZoomIn } from "components/Animations";
import { Link } from "react-router-dom";

interface DropdownContextModel {
  isOpen: boolean;
  toggle(): void;
  open(): void;
  close(): void;
  contentRef: MutableRefObject<any>;
  triggerRef: MutableRefObject<any>;
}

const dropdownContext = createContext<DropdownContextModel | null>(null);

interface DropdownProps {
  defaultValue?: boolean;
  children: ReactNode;
  className?: string;
}

export const Dropdown: FC<DropdownProps> = (props) => {
  const { defaultValue = false, children, className } = props;
  const { ...ctx } = useDropdown(defaultValue);

  return (
    <dropdownContext.Provider value={ctx}>
      <div
        className={`_Dropdown relative inline-block text-left ${className ||
          ""}`}
      >
        {children}
      </div>
    </dropdownContext.Provider>
  );
};

function useDropdownCtx() {
  const dropdownCtx = useContext(dropdownContext);
  if (dropdownCtx === undefined || dropdownCtx === null) {
    throw new Error("useDropdownCtx must be used within a Dropdown");
  }
  return dropdownCtx;
}

/**
 * DropdownButton.
 */

interface DropdownButtonProps extends HTMLAttributes<HTMLButtonElement> {}

export const DropdownButton: FC<DropdownButtonProps> = (props) => {
  const { triggerRef, toggle } = useDropdownCtx();

  return <button type="button" {...props} onClick={toggle} ref={triggerRef} />;
};

/**
 * OptionsButton.
 */
interface OptionsButtonProps extends DropdownButtonProps {
  label?: string;
}

export const OptionsButton: FC<OptionsButtonProps> = (props) => {
  const { label = "Options", ...rest } = props;
  return (
    <DropdownButton
      {...rest}
      className="hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md"
    >
      {label}
      <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" />
    </DropdownButton>
  );
};

/**
 * DropdownContent.
 */
interface DropdownContentProps {
  children: ReactNode;
}

export const DropdownContent: FC<DropdownContentProps> = (props) => {
  const { contentRef, isOpen } = useDropdownCtx();
  const { children } = props;

  return (
    <ZoomIn
      ref={contentRef}
      show={isOpen}
      className="absolute right-0 w-56 mt-2 origin-top-right rounded-md shadow-lg"
    >
      {children}
    </ZoomIn>
  );
};

/**
 * DropdownMenu.
 */

interface DropdownMenuProps {
  children: ReactNode;
}

export const DropdownMenu: FC<DropdownMenuProps> = (props) => {
  const { children } = props;

  return (
    <DropdownContent>
      <div className="_DropdownMenu py-1 bg-white rounded-md shadow-xs">
        {children}
      </div>
    </DropdownContent>
  );
};

/**
 * DropdownMenuLink.
 */
interface DropdownMenuLinkProps {
  to: string;
  color?: AppColor;
}

const colorClassNames: Record<AppColor, string> = {
  teal:
    "text-teal-700 hover:bg-teal-100 hover:text-teal-900 focus:outline-none focus:bg-teal-100 focus:text-teal-900",
  mint:
    "text-mint-700 hover:bg-mint-100 hover:text-dark-teal-900 focus:outline-none focus:bg-mint-100 focus:text-mint-900",
  "accent-green":
    "text-accent-green-700 hover:bg-accent-green-100 hover:text-accent-green-900 focus:outline-none focus:bg-accent-green-100 focus:text-accent-green-900",
  "dark-teal":
    "text-dark-teal-700 hover:bg-dark-teal-100 hover:text-dark-teal-900 focus:outline-none focus:bg-dark-teal-100 focus:text-dark-teal-900",
  gray:
    "text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900",
  blue:
    "text-blue-700 hover:bg-blue-100 hover:text-blue-900 focus:outline-none focus:bg-blue-100 focus:text-blue-900",
  red:
    "text-red-700 hover:bg-red-100 hover:text-red-900 focus:outline-none focus:bg-red-100 focus:text-red-900",
  green:
    "text-green-700 hover:bg-green-100 hover:text-green-900 focus:outline-none focus:bg-green-100 focus:text-green-900",
  yellow:
    "text-yellow-700 hover:bg-yellow-100 hover:text-yellow-900 focus:outline-none focus:bg-yellow-100 focus:text-yellow-900",
  purple:
    "text-purple-700 hover:bg-purple-100 hover:text-purple-900 focus:outline-none focus:bg-purple-100 focus:text-purple-900",
  gold:
    "text-gold-700 hover:bg-gold-100 hover:text-gold-900 focus:outline-none focus:bg-gold-100 focus:text-gold-900",
  orange:
    "text-orange-700 hover:bg-orange-100 hover:text-orange-900 focus:outline-none focus:bg-orange-100 focus:text-orange-900",
  indigo:
    "text-indigo-700 hover:bg-indigo-100 hover:text-indigo-900 focus:outline-none focus:bg-indigo-100 focus:text-indigo-900",
  pink:
    "text-pink-700 hover:bg-pink-100 hover:text-pink-900 focus:outline-none focus:bg-pink-100 focus:text-pink-900",
  navy:
    "text-navy-700 hover:bg-navy-100 hover:text-navy-900 focus:outline-none focus:bg-navy-100 focus:text-navy-900",
};

export const DropdownMenuLink: FC<DropdownMenuLinkProps> = (props) => {
  const { to, color = "gray", children } = props;

  return (
    <Link
      to={to}
      className={`group flex items-center px-4 py-2 text-sm leading-5 ${colorClassNames[color]}`}
    >
      {children}
    </Link>
  );
};

/**
 * DropdownMenuButton.
 */
interface DropdownMenuButtonProps {
  onClick?(): void;
  color?: AppColor;
}

export const DropdownMenuButton: FC<DropdownMenuButtonProps> = (props) => {
  const { onClick, color = "gray", children } = props;

  return (
    <button
      type="button"
      onClick={onClick}
      className={`block w-full text-left px-4 py-2 text-sm leading-5 ${colorClassNames[color]}`}
    >
      {children}
    </button>
  );
};
