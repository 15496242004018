import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FAIcon } from '../FAIcon/FAIcon';
import { AppColor } from 'styles/colorStyles';

function colorClass(color: AppColor): string {
  switch (color) {
    case 'blue':
      return 'bg-blue-200 text-blue-700';
    case 'red':
      return 'bg-red-200 text-red-700';
    case 'orange':
      return 'bg-orange-200 text-orange-700';
    case 'yellow':
      return 'bg-yellow-200 text-yellow-700';
    case 'green':
      return 'bg-green-200 text-green-700';
    case 'purple':
      return 'bg-purple-200 text-purple-700';
    case 'indigo':
      return 'bg-indigo-200 text-indigo-700';
    case 'gold':
      return 'bg-gold-200 text-gold-700';
    case 'navy':
      return 'bg-navy-200 text-navy-700';
    case 'gray':
      return 'bg-gray-200 text-gray-700';
    case 'pink':
      return 'bg-pink-200 text-pink-700';
    case 'teal':
      return 'bg-teal-200 text-teal-700';
    case 'mint':
      return 'bg-mint-200 text-mint-700';
    case 'accent-green':
      return 'bg-accent-green-200 text-accent-green-700';
    case 'dark-teal':
      return 'bg-dark-teal-200 text-dark-teal-700';
  }
}

type CircleIconProps = {
  icon: IconProp;
  size?: number;
  iconSize?: number;
  color?: AppColor;
};

export const CircleIcon: FC<CircleIconProps> = props => {
  const { icon, size, iconSize, color = 'accent-green' } = props;
  return (
    <div
      className={`flex-shrink-0 h-10 w-10 flex justify-center items-center ${colorClass(
        color
      )} rounded-full`}
      style={!!size ? { width: size, height: size } : {}}
    >
      {!!iconSize ? (
        <span style={{ fontSize: iconSize }}>
          <FAIcon icon={icon} />
        </span>
      ) : (
        <FAIcon icon={icon} />
      )}
    </div>
  );
};
